import { Draftable, Updateable, Taggable, Notifiable } from '@/types/roster'
import api from '.'

export default {
  createDraft(payload: Draftable) {
    return api.post('/entries', payload)
  },

  deleteEntry(entryId: number) {
    return api.delete(`/entries/${entryId}`)
  },

  approveEntry(entryId: number) {
    return api.put(`/entries/${entryId}/approve`)
  },

  updateLocum(entryId: number, payload: Updateable) {
    return api.put(`/entries/${entryId}/update_locum`, payload)
  },

  tagEntry(entryId: number, payload: Taggable) {
    return api.put(`/entries/${entryId}/tag`, payload)
  },

  notifyAdmins(entryId: number, payload: Notifiable) {
    return api.put(`/entries/${entryId}/notify`, payload)
  },
}